import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PageLoading from './components/PageLoading/PageLoading'



const Home = lazy(()=>import('./pages/Home/Home'))
const GetAQuote = lazy(()=>import('./pages/GetAQuote/GetAQuote'))
const Collaboration = lazy(()=>import('./pages/Collaboration/Collaboration'))
const WhatWeOffer = lazy(()=>import('./pages/WhatWeOffer/WhatWeOffer'))
const AboutUs = lazy(()=>import('./pages/AboutUs/AboutUs'))
const TandC = lazy(()=>import('./pages/TermsAndCondition/termsandconditions'))
const Partner = lazy(()=>import('./pages/Partners/Partners'))
const Privacy = lazy(()=>import('./pages/Privacy'))
const Overview = lazy(()=>import('./pages/Careers/Overview'))
const Apply = lazy(()=>import('./pages/Careers/Application'))
const Career = lazy(()=>import('./pages/Careers/Career'))


const NotFoundPage = lazy(()=>import('./components/NotFoundPage/NotFoundPage'))

 
const AllRoutes = ({isDark}) => {
  return (
    
    <Suspense fallback={<PageLoading isDark={isDark}/>}>
    
    <Routes>
      <Route path='/' element={<Home isDark={isDark}/>}/>
      <Route path='/partners' element={<Partner isDark={isDark}/>}/>
      <Route path='/privacy' element={<Privacy isDark={isDark}/>}/>
      <Route path='/getaquote' element={<GetAQuote isDark={isDark}/>}/>
      <Route path='/collaboration' element={<Collaboration isDark={isDark}/>}/>
      <Route path='/whatweoffer' element={<WhatWeOffer isDark={isDark}/>}/>
      <Route path='/careers/overview/:careerName/:whatweExpect/:jobID' element={<Overview isDark={isDark}/>}/>
      <Route path='/careers/apply/:careerName/:whatweExpect/:jobID' element={<Apply isDark={isDark}/>}/>
      <Route path='/aboutus' element={<AboutUs isDark={isDark}/>}/>
      <Route path='/careers' element={<Career isDark={isDark}/>}/>
      <Route path='/termsandconditions' element={<TandC/>}/>
      <Route path='/aboutus' element={<AboutUs isDark={isDark}/>}/>
      <Route path='*' element={<Home isDark={isDark}/>}/>
    </Routes>
    </Suspense>

  )
}

export default AllRoutes
