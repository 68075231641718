// Preloader.js
import React, { useState, useEffect } from 'react';
import './pageloader.css'; // Import your CSS file

const Preloader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simulate content loading
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 3000); // Adjust the time (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`preloader ${loaded ? 'hide' : ''}`}>
      <div className="loader"></div>
    </div>
  );
};

export default Preloader;
