import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SliderBtn = ({ normalText, hoverText, to }) => {
  return (
    <div>
      <Link to={to}>
        <button className="relative inline-flex items-center justify-center px-6 py-3 text-sm font-semibold text-white rounded-md bg-orange-500 border-none overflow-hidden group focus:outline-none active:top-0.5">
          {/* Hover and active states for button text */}
          <span className="absolute top-1/2 left-0 w-full transform -translate-x-full -translate-y-1/2 opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100">
            {hoverText}
          </span>
          <span className="transition-all duration-300 ease-in-out transform group-hover:translate-x-full group-hover:opacity-0">
            {normalText}
          </span>
        </button>
      </Link>
    </div>
  );
};

SliderBtn.propTypes = {
  normalText: PropTypes.string.isRequired,
  hoverText: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired, // New prop for the link destination
};

export default SliderBtn;