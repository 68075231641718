import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation
import SliderBtn from "../Buttons/SliderBtn";
import DGatewayinLogo from "../../assets/DGatewayinLogo.jpeg";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);
  const location = useLocation(); // Use useLocation hook to get current path

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasShadow(true);
      } else {
        setHasShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getLinkClass = (path) => {
    return location.pathname === path
      ? "text-orange-500"
      : "text-gray-600  hover:text-orange-500";
  };

  return (
    <nav className={`bg-white fixed top-0 left-0 w-full z-10 transition-shadow duration-300 ${hasShadow ? "shadow-md" : ""}`}>
      <div className="container max-w-screen-2xl px-6 py-4 lg:py-5 lg:px-16 xl:px-24 2xl:px-[80px] mx-auto">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="flex items-center justify-between">
  
              <Link to="/" className=" flex items-center">
                <img
                  src={DGatewayinLogo}
                  alt="DGateway Logo"
                  className="h-8 inline-block mr-1"
                />
                <span className="font-semibold text-orange-500 text-2xl">
                  GatewayIn
                </span>
              </Link>

         
     
            <div className="flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                aria-label="toggle menu"
              >
                {!isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div className={`absolute bg-white shadow-md lg:shadow-none inset-x-0 z-20 w-full px-6 pt-4 pb-8 transition-all duration-300 ease-in-out lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center ${isOpen ? "translate-x-0 opacity-100" : "opacity-0 -translate-x-full"}`}>
            <div className="flex flex-col -mx-6 lg:flex-row lg:items-center lg:mx-8">
              <Link to="/" className={`px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 tracking-wide antialiased ${getLinkClass("/")}`}>
                Home
              </Link>
              <Link to="/aboutus" className={`px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 tracking-wide antialiased ${getLinkClass("/aboutus")}`}>
                About
              </Link>
              <Link to="/whatweoffer" className={`px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 tracking-wide antialiased ${getLinkClass("/whatweoffer")}`}>
                Services
              </Link>
              <Link to="/careers" className={`px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 tracking-wide antialiased ${getLinkClass("/careers")}`}>
                Careers
              </Link>
              <Link to="/partners" className={`px-3 py-2 mx-3 mt-2 transition-colors duration-300 transform rounded-md lg:mt-0 tracking-wide antialiased ${getLinkClass("/partners")}`}>
                Partners
              </Link>
            </div>
          </div>

          <div className="lmt-4 hidden lg:mt-0 lg:flex gap-x-4">
            <SliderBtn normalText="Careers" hoverText="Get Ready" to="/getaquote" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;