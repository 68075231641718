import React from "react";
import FooterBanner from "./FooterBanner";
import Book from "./Book";
import DGatewayinLogo from "../../assets/DGatewayinLogo.jpeg";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation



const Footer = () => {
  const calendlyUrl = "https://calendly.com/hello-lg5z/30min?background_color=fff6f3&primary_color=f0512f";

  const openCalendly = () => {
    window.open(calendlyUrl, '_blank');
  };
  return (

    <footer className="bg-white">

      <div className="container max-w-screen-2xl px-6 pb-4 lg:px-16  xl:px-24 2xl:px-[80px]  mx-auto">
        <div className="flex flex-col items-center gap-4 rounded-lg  bg-orange-500 p-6 shadow-lg sm:flex-row sm:justify-between">
          <strong className="text-xl text-white sm:text-xl"> Make Your Next Move! </strong>

          <button aria-label="Watch how it works" 
            className="group inline-flex items-center ml-5  text-black justify-center px-6 py-3  text-base rounded-md bg-white   overflow-hidden group focus:outline-none active:top-0.5"
            onClick={openCalendly}
            >
            Book a Demo
            <svg
              className="ml-2 size-5 rtl:rotate-18 transition-transform group-hover:translate-x-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </button>


        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="text-center sm:text-left">
            <p className="text-lg font-medium text-gray-900">About Us</p>

            <ul className="mt-8 space-y-4 text-sm">
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#">
                  Company History
                </a>
              </li>

              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> Meet the Team </a>
              </li>

              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#">
                  Employee Handbook
                </a>
              </li>

              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> Careers </a>
              </li>
            </ul>
          </div>

          <div className="text-center sm:text-left">
            <p className="text-lg font-medium text-gray-900">Our Services</p>

            <ul className="mt-8 space-y-4 text-sm">
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> UI / UX Designing</a>
              </li>

              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> Artificial intelligence</a>
              </li>

              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> Business Consultation </a>
              </li>
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> Web App Development</a>
              </li>
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#">Mobile App Development </a>
              </li>
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> Digital Marketing</a>
              </li>
            </ul>
          </div>

          <div className="text-center sm:text-left">
            <p className="text-lg font-medium text-gray-900">Resources</p>

            <ul className="mt-8 space-y-4 text-sm">
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> 
                  Blogs
                </a>
              </li>

              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#">
                  Reviews
                </a>
              </li>
              
            </ul>
          </div>

          <div className="text-center sm:text-left">
            <p className="text-lg font-medium text-gray-900">Helpful Links</p>

            <ul className="mt-8 space-y-4 text-sm">
              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> FAQs </a>
              </li>

              <li>
                <a className="text-gray-700 transition hover:text-gray-700/75" href="#"> Contact us </a>
              </li>


            </ul>
          </div>
        </div>

        <div className="mt-16">
          <ul className="flex justify-center gap-6 sm:justify-end">
            <li>
              <a
                href="https://www.facebook.com/share/kf48CrLKqh4H1ysN/?mibextid=LQQJ4d"
                rel="noreferrer"
                target="_blank"
                className="text-gray-700 transition"
              >
                <span className="sr-only">Facebook</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                className="text-gray-700 transition"
              >
                <span className="sr-only">Instagram</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/company/dgatewayin/"
                rel="noreferrer"
                target="_blank"
                className="text-gray-700 transition"
              >
                <span className="sr-only">Linked in</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11.75 19h-2.5v-9h2.5v9zm-1.25-10.23c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.75 10.23h-2.5v-4.5c0-1.07-.93-1.94-2-1.94s-2 .87-2 1.94v4.5h-2.5v-9h2.5v1.19c.73-.87 1.83-1.19 3-1.19 2.21 0 4 1.79 4 4v5z"
                  clipRule="evenodd"
                />
                </svg>
              </a>
            </li>
          </ul>

          <div className="mt-16 sm:flex sm:items-center sm:justify-between">
            <div className="flex justify-center   text-orange-500 text-xl sm:justify-start">
              <Link to="/" className=" flex items-center">
                  <img
                    src={DGatewayinLogo}
                    alt="DGateway Logo"
                    className="h-8 inline-block mr-1"
                  />
                  <span className="font-semibold text-orange-500 text-2xl">
                    GatewayIn
                  </span>
                </Link>
            </div>

            <p className="mt-4 text-center text-sm text-gray-500 sm:mt-0 sm:text-right">
              DGatewayIn &copy; 2024 All rights reserved.
            </p>
          </div>

        </div>


      </div>


    </footer>
  );
};

export default Footer;
