import './App.css';
import AllRoutes from './AllRoutes';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import PageLoading from './components/PageLoading/PageLoading'; 
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTopOnMount from './components/ScrollToTop';

function App() {

  
  const [isDark, setIsDark] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      // Simulating data fetching delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [currentPage, setCurrentPage] = useState('home');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  
  return (
    <>


      

    <BrowserRouter>
    
    <Header  onPageChange={handlePageChange} />
      <ScrollToTopOnMount/>
      {isLoading ? (
        <PageLoading isDark={isDark} />
      ) : (
        <>
          <AllRoutes isDark={isDark}/>
          <Footer isDark={isDark} currentPage={currentPage} onPageChange={handlePageChange}/>
        </>
        
      )}
      
      </BrowserRouter>

    </>
  );
}

export default App;
